.store-search-header {
  margin-top: 10px;

  h3 {
    font-weight: 600;
    font-size: 26px;
    color: #4F4F4F;
    font-family: $merriweather;
    position: relative;
    display: inline-block;

    @media(max-width: 767px) {
      font-size: 22px;
    }
  }

  p {
    display: inline-block;
    margin-left: 20px;

    @media(max-width: 767px) {
      display: block;
      margin-left: 0;
    }
  }
}
