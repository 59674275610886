/* config variables */
@import "../../variables";

/* public modules */
@import "../modules/mixins";
@import "../modules/select";
@import "../modules/buttons";
@import "../modules/custom_effects";

/* snippets */
@import "../snippets/slider_effects";
@import "../snippets/slider_element";

/* bootstrap */
@import "../../bootstrap/bootstrap";
@import "../../bootstrap/overrides";

/* vendor */
@import "../../vendor/animate";
@import "../../vendor/magnific.popup";

/* icons */
@import "../../vendor/ionicons";
@import "../../vendor/font-awesome";

/* layout */
@import "layout";

/* ecommerce modules */
@import "modules/elements";

/* ecommerce */
@import "pages/index";
@import "pages/collection";
@import "pages/collection_sidebar";
@import "pages/product";
@import "pages/search";
@import "pages/cart";
@import "pages/checkout";
@import "pages/account_orders";
@import "pages/account_invoice";
@import "pages/account_wishlist";
@import "pages/account_profile";
@import "pages/sign_up";
