.profile-form {
  margin-top: 30px;

  @media(max-width: 767px) {
    margin-top: 10px;
  }

  .avatar {
    border-radius: 100%;
    width: 100px;
    margin-left: 8px;
  }

  .btn-upload-avatar {
    margin-top: 15px;
    display: block;

    @media(max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  [type="submit"] {
    margin-top: 20px;
  }
}
