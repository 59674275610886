.store-product-details {
  margin-top: 40px;

  .main-pic {
    background-size: cover;
    background-position: center center;
    width: 93%;
    min-height: 400px;
    text-decoration: none;
    display: block;

    @media(max-width: 991px) {
      min-height: 350px;
      width: 100%;
    }

    @media(max-width: 767px) {
      min-height: 330px;
    }
  }

  .thumbs {
    margin-top: 20px;
    width: 93%;

    @media(max-width: 991px) {
      width: 100%;
      margin-top: 10px;
    }

    .thumb {
      position: relative;
      display: inline-block;
      text-decoration: none;
      width: 105px;
      height: 100px;
      margin-right: 10px;
      background-size: cover;
      background-position: center center;

      @media(max-width: 991px) {
        width: 23%;
        height: 70px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        .mask {
          opacity: 1;
        }
      }

      .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.5);
        opacity: 0;
        text-align: center;

        @include transition(all .2s);

        i {
          position: relative;
          top: 40px;
          color: #fff;
          font-size: 20px;
        }
      }
    }
  }

  .info {
    position: relative;

    @media(max-width: 767px) {
      margin-top: 40px;
    }

    .share {
      position: absolute;
      right: 10px;
      top: 7px;

      a {
        margin-left: 13px;
        color: #95ABBD;
        font-size: 17px;
        text-decoration: none;

        i.fa-twitter,
        i.fa-facebook,
        i.fa-pinterest {
          position: relative;
          top: -1px;
        }
      }
    }

    .name {
      font-size: 27px;
      font-family: $merriweather;

      @media(max-width: 991px) {
        font-size: 25px;
      }

      @media(max-width: 767px) {
        font-size: 24px;
      }
    }

    .rating {
      margin-top: 4px;

      i {
        margin-right: 2px;
        color: #64B1CD;
      }
    }

    .price {
      font-size: 30px;
      color: #70a90f;
      margin-top: 7px;
      font-family: $georgia;

      @media(max-width: 991px) {
        font-size: 27px;
      }

      .compare-at-price {
        color: #a3a3a3;
        display: inline-block;
        font-size: 18px;
        margin-left: 8px;
        text-decoration: line-through;
      }
    }

    .variants {
      margin-top: 40px;

      .variant {
        margin-bottom: 20px;

        label {
          display: inline-block;
          font-weight: 600;
          width: 80px;
          color: #555;

          &.label-variant-colors {
            position: relative;
            top: -12px;
          }
        }

        .variant-colors {
          display: inline-block;

          .variant-color {
            position: relative;
            float: left;
            width: 34px;
            height: 34px;
            margin-right: 8px;
            border-radius: 100%;
            cursor: pointer;

            &.selected {
              border: 1px solid #ddd;
            }

            &:after {
              content: '';
              position: absolute;
              left: 8px;
              top: 8px;
              width: 16px;
              height: 16px;

              @include transform(rotate(45deg));
            }
            
            &--grey:after {
              background: #dadada;
            }

            &--blue:after {
              background: #7daef1;
            }

            &--black:after {
              background: #333;
            }
          } 
        }

        .input-qty {
          display: inline-block;
          width: 55px;
          height: 32px;
          padding-right: 6px;
        }
      }
    }

    .add-to-cart {
      margin-top: 30px;

      [type="submit"] {
        margin-left: 81px;
        font-weight: 500;
        padding: 11px 17px;
        outline: none;

        &:hover {
          background-color: #415779;
        }

        i {
          margin-right: 6px;
        }
      }
    }

    .description {
      margin-top: 40px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;

      .nav-tabs {
        li:not(.active) {
          a:hover {
            background: #fdfdfd;
          }
        }
      }

      .tab-content {
        padding: 15px;
        padding-top: 25px;

        .tab-pane {
          line-height: 22px;
          color: #444;

          p {
            margin-bottom: 25px;
          }

          ul {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.other-products {
  margin-top: 90px;
  
  h3 {
    margin-bottom: 60px;
    font-size: 20px;
    color: #454545;
    padding-top: 50px;
    border-top: 1px solid #e7e7e7;
  }

  .store-product {
    min-height: 350px;
    margin-bottom: 0;

    a .image {
      height: 200px;
    }
  }
}
